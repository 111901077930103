
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, computed } from "vue"
import { SelectModel } from "@/core/models/SelectModel"
import { ElMessage, UploadProps, ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { today } from "@/core/helpers/dateformat"
import { PosReport } from "@/core/models/PosReport"
import { BankAccountType } from "@/core/models/BankAccountType"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import JwtService from "@/core/services/JwtService"

export default defineComponent({
  name: "pos-cash-out",
  components: {
    ElInputCurrency,
  },
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const appUrl = process.env.VUE_APP_URL ?? ""

    const posReport = ref<PosReport>({
      reportDate: today(),
    } as PosReport)

    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isCashBookSelectLoading = ref(false)

    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])
    const cashBookList = ref<SelectModel[]>([])

    const activeBusinessId = ref<string>()
    const activeBranchId = ref<string>()

    const dialogVisible = ref(false)
    const dialogImageUrl = ref("")

    const showSlip = () => {
      dialogVisible.value = true
      dialogImageUrl.value = posReport.value.reportImageUrl ?? ""
    }

    const rules = ref({
      activeBusinessId: getRule(RuleTypes.SELECT, "İşletme"),
      activeBranchId: getRule(RuleTypes.SELECT, "Şube"),
      cashBookId: getRule(RuleTypes.SELECT, "Kasa"),
      reportDate: getRule(RuleTypes.DATE, "Rapor"),
    })

    const formData = computed(() => ({
      activeBusinessId: activeBusinessId.value,
      activeBranchId: activeBranchId.value,
      ...posReport.value,
    }))

    const pickerOptions = {
      disabledDate(date) {
        const today = new Date().setHours(0, 0, 0, 0)
        return date > today
      },
    }

    const disabledDate = computed(() => pickerOptions.disabledDate)

    const OnChangeBusiness = async businessId => {
      resetForm()
      branchList.value = []
      cashBookList.value = []
      await getBranchList(businessId)
    }

    const OnChangeBranch = branchId => {
      resetForm()
      if (activeBranchId.value != "" && activeBranchId.value != undefined) {
        getBranchCashBookList(branchId)
      }
    }

    const OnChangeDate = async () => {
      if (activeBranchId.value != "" && activeBusinessId.value != "") {
        getPosReport()
      }
    }

    const resetForm = () => {
      posReport.value = {
        reportDate: today(),
      } as PosReport
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        await OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        activeBranchId.value = branchList.value[0].id
        OnChangeBranch(activeBranchId.value)
      }
    }

    const getBranchCashBookList = async branchId => {
      isCashBookSelectLoading.value = true
      const { data } = await ApiService.get("cash-book/branch/" + branchId)
      isCashBookSelectLoading.value = false
      cashBookList.value = data
        .filter(x => x.bankAccountType == BankAccountType.POS)
        .map(x => ({
          id: x.id,
          name: x.name,
          balance: x.balance || undefined,
        }))
      if (cashBookList.value.length === 1) {
        posReport.value.cashBookId = cashBookList.value[0].id
      }
    }

    const formValidate = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          getPosReport()
        }
      })
    }

    const getPosReport = async () => {
      const query = {
        params: {
          reportDate: posReport.value.reportDate,
          cashBookId: posReport.value.cashBookId,
        },
      }
      try {
        const { data } = await ApiService.query(`pos-report`, query)
        posReport.value = {
          ...data,
          reportImageUrl: appUrl + "/" + data.reportImageUrl,
        }
      } catch {
        posReport.value.id = ""
      }
    }

    const updatePosReport = async () => {
      const data = {
        id: posReport.value.id,
        reportImageId: posReport.value.reportImageId,
      }
      await ApiService.post("pos-report/edit", data)
    }

    const handlePosReportPictureSuccess: UploadProps["onSuccess"] = async (
      response,
      uploadFile
    ) => {
      posReport.value = {
        ...posReport.value,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        reportImageUrl: URL.createObjectURL(uploadFile.raw!),
        reportImageId: response[0],
      }
      await updatePosReport()
    }

    const beforePosReportPictureUpload: UploadProps["beforeUpload"] = rawFile => {
      const allowedTypes = ["image/jpeg", "image/png"]
      if (!allowedTypes.includes(rawFile.type)) {
        ElMessage.error("Slip resmi Jpeg veya Png formatında olmalıdır")
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error("2MB'dan fazla olamaz")
        return false
      }
      return true
    }

    const authHeader = {
      Authorization: `Bearer ${JwtService.getToken()}`,
    }

    onMounted(async () => {
      await getBusinessList()
    })

    return {
      appUrl,
      rules,
      formData,
      posReport,
      ruleFormRef,
      authHeader,

      isBusinessSelectLoading,
      isBranchSelectLoading,
      isCashBookSelectLoading,

      getBusinessList,
      getBranchList,

      OnChangeBusiness,
      OnChangeBranch,
      OnChangeDate,

      getPosReport,
      formValidate,
      updatePosReport,

      activeBusinessId,
      activeBranchId,
      branchList,
      businessList,
      cashBookList,

      pickerOptions,
      disabledDate,
      handlePosReportPictureSuccess,
      beforePosReportPictureUpload,
      dialogVisible,
      dialogImageUrl,
      showSlip,
    }
  },
})
